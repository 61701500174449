<template>
  <div>
    <div class="summer-league">
      <div
        v-for="p in alphaPlayers"
        :key="'player' + p.firstName + p.lastName"
        class="player"
        @click="select(p.id)"
      >
        {{ p.firstName + " " + p.lastName }}
        <div class="handicap">{{ p.handicap }}</div>
      </div>
    </div>
    <div class="prefers">
      <div
        v-for="p in prefPlayers"
        :key="'player' + p.firstName + p.lastName"
        class="pref player"
        @click="select(p.id)"
      >
        {{ p.firstName + " " + p.lastName }}
        <div class="handicap">
          {{ p.handicap + " / " + p.availability + "%" }}
        </div>
      </div>
    </div>
    <div class="selected-section">
      <div
        v-for="p in selectedPlayers"
        :key="'player' + p.firstName + p.lastName"
        class="selected player"
        @click="unselect(p.id)"
      >
        {{ p.firstName + " " + p.lastName }}
        <div class="handicap">
          {{ p.handicap + " / " + p.availability + "%" }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint no-undef: 0 */
import players from "./players.json";

export default {
  name: "Quiz",
  beforeCreate: function () {
    document.body.className = "summer";
  },
  data() {
    return {
      players: players,
      alphaPlayers: [],
      prefPlayers: [],
      selected: [],
    };
  },
  mounted() {
    this.updatePlayers();
  },
  components: {},
  methods: {
    updatePlayers: function () {
      this.alphaPlayers = this.players
        .filter((a) => !this.selected.includes(a.id))
        .sort((a, b) => {
          if (a.lastName > b.lastName) {
            return 1;
          }
          return -1;
        });
      this.prefPlayers = this.players.filter(
        (a) => !this.selected.includes(a.id)
      );
      this.selectedPlayers = this.players
        .filter((a) => this.selected.includes(a.id))
        .sort((a, b) => {
          if (a.lastName > b.lastName) {
            return 1;
          }
          return -1;
        });
    },
    select: function (id) {
      this.selected.push(id);
      this.updatePlayers();
    },
    unselect: function (id) {
      this.selected = this.selected.filter((a) => a != id);
      this.updatePlayers();
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
div.summer-league {
  overflow: scroll;
  height: 40vh;
  padding-bottom: 5px;
}
div.prefers {
  border-top: 2px solid red;
  overflow: scroll;
  height: 40vh;
}

div.selected-section {
  border-top: 2px solid red;
  overflow: scroll;
  height: 15vh;
}

div.player {
  background-color: #005;
  width: fit-content;
  padding: 5px;
  color: white;
  border-radius: 2px;
  font-family: Verdana, sans-serif;
  font-size: 10px;
  display: inline-block;
  margin: 4px;
  cursor: pointer;
}

div.pref.player {
  background-color: #128;
}

div.selected.player {
  background-color: #388;
}

div.player:hover {
  background-color: #449;
}

div.handicap {
  background-color: purple;
  padding: 3px;
  display: inline-block;
}
</style>

